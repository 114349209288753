<template>
	<div class="log">
		<a-spin :spinning="loading">
			<a-form ref="formRef" :model="modelRef" @finish="onSearch">
				<a-row>
					<a-form-item name="username" class="ui-form-item" label="操作者名称" style="margin-right: 30px;">
						<a-input v-model:value="modelRef.username" placeholder="请输入" />
					</a-form-item>

					<a-form-item name="reqUrl" class="ui-form-item" label="请求URL">
						<a-input v-model:value="modelRef.reqUrl" placeholder="请输入" />
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="10">
					</a-col>
					<a-col :span="14" style="text-align: right;">
						<a-button style="margin-right: 20px;" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="resetBtn">重置</a-button>
					</a-col>
				</a-row>
			</a-form>
			<a-table style="margin-top: 10px;" :dataSource="dataList" :pagination="pagination" :columns="columns" bordered size="middle"
				rowKey="id">
				<template #bodyCell="{ column, record }">
					<template v-if="column.userName === 'title'">
						{{ record.userName }}
					</template>
					<template v-if="column.dataIndex === 'operate'">
						<a-dropdown>
							<a @click.prevent>操作 <Icon icon="DownOutlined"></Icon></a>
							<template #overlay>
								<a-menu>
									<a-menu-item>
										<a>编辑</a>
									</a-menu-item>
									<a-menu-item>
										<a>用户角色</a>
									</a-menu-item>
									<a-menu-item>
										<a>禁用</a>
									</a-menu-item>
									<a-menu-item>
										<a>删除</a>
									</a-menu-item>
								</a-menu>
							</template>
						</a-dropdown>
					</template>
				</template>
			</a-table>
		</a-spin>
	</div>
</template>

<script>
	import {
		getLog
	} from '@/service/modules/system';
	import {
		Icon
	} from "@/components/icon/icon.js";
	export default {
		components: {
			Icon
		},
		data() {
			return {
				loading: false,
				modelRef: {},
				searchData: {},
				dataList: [],
				columns: [{
						title: "操作方法",
						dataIndex: "operation",
						width: "30%",
					},
					{
						title: "操作者ID",
						dataIndex: "adminUserId",
						width: "10%",
					},
					{
						title: "操作者",
						dataIndex: "username",
						width: "20%",
					},
					{
						title: "请求URL",
						dataIndex: "reqUrl",
						width: "30%",
					},
					{
						title: "耗时",
						dataIndex: "fishTime",
						width: "10%",
					},
				],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 20,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.getData();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.modelRef));
				this.getData();
			},
			resetBtn() {
				this.$refs.formRef.resetFields();
				this.onSearch();
			},
			getData() {
				this.loading = true;
				getLog({
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					...this.searchData
				}).then(res => {
					this.loading = false;
					if (res.code == 200) {
						this.dataList = res.data.list;
						this.pagination.total = res.data.totalCount;
					}
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	.log {
		// background: red;
		// padding: 20px;
	}
</style>
